import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle as ToggleApprovalIcon,
  faSpinner as LoadingIndicatorIcon,
  faTimesCircle as CancelIcon,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import DatabaseService from 'services/DatabaseService'
import LoadingDots from 'components/shared/Forms/LoadingDots'

import styles from './List.module.scss'
import { getRoute } from 'utils/index'

function RentalListItem(props) {
  const { rental = {} } = props
  const [working, setWorking] = useState(false)

  const {
    $id: id,
    $ref,
    approved = false,
    cancelled = false,
    email = '',
    newsletterSubscribed = true,
    item_img = '',
    item_name = '',
    lender_name = '',
    renter_name = '',
    lender = {},
    renter = {},
    item = {},
    price = {},
    city = '',
    num_days = 1,
    end_date = null,
    start_date = null,
    createdAt = null,
  } = rental
  const item_id = item.id
  const renter_id = renter.id
  const lender_id = lender.id

  // When approved changes set working to false
  useEffect(() => {
    setWorking(false)
  }, [approved])

  const toggleApproval = async () => {
    if (working) {
      return
    }
    /** @type {firebase.firestore.DocumentReference} */
    const ref = $ref
    if (!ref) {
      throw 'Cannot set ref. ref was not found'
    }
    setWorking(true)
    try {
      await ref.update({ approved: !approved })
    } catch (error) {
      setWorking(false)
    }
  }

  const toggleCancel = async () => {
    if (working) {
      return
    }
    /** @type {firebase.firestore.DocumentReference} */
    const ref = $ref
    if (!ref) {
      throw 'Cannot set ref. ref was not found'
    }
    setWorking(true)
    try {
      await ref.update({ cancelled: true })
    } catch (error) {
      setWorking(false)
    }
  }

  return (
    <div className={styles.itemListItem}>
      <div className={styles.itemImages}>
        <a href={getRoute('ITEM', { id: item_id })} target="blank">
          <img src={item_img} />
        </a>
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.topLine}>
          <span>
            <a href={getRoute('ITEM', { id: item_id })} target="blank">
              {item_name}
            </a>
          </span>{' '}
          by
          <a href={getRoute('PROFILE', { id: lender_id })} target="blank">
            {' '}
            {lender_name}
          </a>
        </div>
        <div className={styles.midLine}>
          <span>
            Renter:
            <a href={getRoute('PROFILE', { id: renter_id })} target="blank">
              {' '}
              {renter_name}
            </a>
          </span>
          <span>{createdAt && moment(createdAt.toDate()).fromNow()}</span>
        </div>
        <div className={styles.botLine}>
          <span>
            {start_date && moment(start_date.toDate()).format('MM/DD')}-
            {end_date && moment(end_date.toDate()).format('MM/DD')}
          </span>
          <span>
            {' ᛫ '} {num_days} day(s)
          </span>
        </div>
        <div className={styles.botLine}>
          <span>Total Price: {price.formatted && price.formatted.total}</span>
        </div>
      </div>
      <div className={styles.itemActions}>
        <div className={styles.action} onClick={toggleApproval}>
          {working ? (
            <Icon color={'gray'} spin icon={LoadingIndicatorIcon} />
          ) : (
            <Icon
              color={approved ? 'green' : 'rgba(0,0,0,0.2)'}
              icon={ToggleApprovalIcon}
            />
          )}
        </div>
        <a className={styles.action} onClick={toggleCancel}>
          <Icon
            icon={CancelIcon}
            color={cancelled ? 'red' : 'rgba(0,0,0,0.2)'}
          />
        </a>
      </div>
    </div>
  )
}

export default function RentalList(props) {
  const { cancelled, pending, initialItems = [] } = props
  const [rentals, setItems] = useState(initialItems)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  function updateItems(newRentals) {
    // console.log('newItems',newItems)
    setItems(newRentals)
    setLoading(false)
  }

  useEffect(() => {
    // don't load from firebase if items are supplied to the the component
    if (initialItems.length) {
      return
    }
    // load users from the database
    /** @type {firebase.firestore.CollectionReference} */
    let collection = DatabaseService.firestore.collection(
      DatabaseService.COLLECTIONS.rentals
    )
    if (cancelled === true) {
      collection = collection.where('cancelled', '==', true)
    } else if (pending === true) {
      collection = collection.where('approved', '==', false)
    } else if (pending === false) {
      collection = collection.where('approved', '==', true)
    }
    return collection.orderBy('createdAt', 'desc').onSnapshot({
      next: ({ docs } = {}) => {
        updateItems(
          docs.map(doc => ({
            ...doc.data(),
            $id: doc.id,
            $ref: doc.ref,
          }))
        )
      },
      error: error => {
        console.error(error)
        setLoading(false)
        setError(error)
      },
    })
  }, [initialItems])

  if (loading) {
    return (
      <div className={styles.loadingBox}>
        <LoadingDots loading={true} />
      </div>
    )
  }

  if (error) {
    return <div>Something went wrong....</div>
  }

  return (
    <div className={styles.container}>
      <div className={styles.total}>Total: {rentals.length}</div>
      {rentals.map(rental => {
        return <RentalListItem key={`rental-${rental.$id}`} rental={rental} />
      })}
    </div>
  )
}
