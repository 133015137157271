import React from 'react'

import TabNavigator from 'components/TabNavigator/TabNavigator'
import styles from './ItemSection.module.scss'
import ItemList from 'components/ModLists/ItemList'
import RentalList from 'components/ModLists/RentalList'

function CustomTabItem({ active, children }) {
  return (
    <div
      className={[active ? styles.active : '', styles.customTabItem].join(' ')}
    >
      {children}
    </div>
  )
}

export function RentalSection() {
  const tabs = [
    {
      content: active => <CustomTabItem active={active}>All</CustomTabItem>,
    },
    {
      content: active => <CustomTabItem active={active}>Pending</CustomTabItem>,
    },
    {
      content: active => (
        <CustomTabItem active={active}>Approved</CustomTabItem>
      ),
    },
    {
      content: active => (
        <CustomTabItem active={active}>Cancelled</CustomTabItem>
      ),
    },
  ]
  return (
    <TabNavigator
      className={styles.container}
      tabContainerClass={styles.tabContainer}
      tabs={tabs}
    >
      <RentalList />
      <RentalList pending={true} />
      <RentalList pending={false} />
      <RentalList cancelled={true} />
    </TabNavigator>
  )
}

/**
 * ItemSection
 *
 * Used Mainly in the admin dashboard but could
 * be repurposed to work in other places in the
 * future.....maybe
 */
export function ItemSection() {
  const tabs = [
    {
      content: active => <CustomTabItem active={active}>All</CustomTabItem>,
    },
    {
      content: active => <CustomTabItem active={active}>Pending</CustomTabItem>,
    },
    {
      content: active => (
        <CustomTabItem active={active}>Approved</CustomTabItem>
      ),
    },
  ]
  return (
    <TabNavigator
      className={styles.container}
      tabContainerClass={styles.tabContainer}
      tabs={tabs}
    >
      <ItemList />
      <ItemList pending={true} />
      <ItemList pending={false} />
    </TabNavigator>
  )
}
