import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle as ToggleApprovalIcon,
  faSpinner as LoadingIndicatorIcon,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import DatabaseService from 'services/DatabaseService'
import LoadingDots from 'components/shared/Forms/LoadingDots'

import styles from './List.module.scss'
import { getRoute } from 'utils/index'

function UserListItem(props) {
  const { item = {} } = props
  const [working, setWorking] = useState(false)

  const {
    $id: id,
    $ref,
    approved = false,
    email = '',
    phone = '',
    phoneNumber = '',
    newsletterSubscribed = true,
    socialVerified = false,
    paymentSources = [],
    sources = [], // backward compatibility
    description = '',
    stripe_scope = '',
    picture = '',
    firstName = '',
    lastName = '',
    city = '',
    createdAt = null,
  } = item
  console.log('item', item)
  // When approved changes set working to false
  useEffect(() => {
    setWorking(false)
  }, [approved])

  return (
    <div className={styles.itemListItem}>
      <div className={styles.itemImages}>
        <a href={getRoute('PROFILE', { id })} target="blank">
          <img src={picture} />
        </a>
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.topLine}>
          <a href={getRoute('PROFILE', { id })} target="blank">
            <span>
              {firstName} {lastName}
            </span>
          </a>{' '}
          ({email}
          {phone && ', ' + phone}
          {phoneNumber && ', ' + phoneNumber})
        </div>
        <div className={styles.midLine}>
          {city && <span>{city}</span>}
          <span>{createdAt && moment(createdAt.toDate()).fromNow()}</span>
        </div>
        <div className={styles.bline}>
          <span>Social verified: {socialVerified ? 'True' : 'False'}</span>
        </div>
        <div className={styles.bline}>
          <span>
            Has rented:{' '}
            {paymentSources.length || sources.length ? 'True' : 'False'}
          </span>
        </div>
        <div className={styles.bline}>
          <span>
            Completed stripe onboarding: {stripe_scope ? 'True' : 'False'}
          </span>
        </div>
        <div className={styles.bline}>
          {description && <span>{description}</span>}
        </div>
      </div>
    </div>
  )
}

export default function UserList(props) {
  const { pending, initialItems = [] } = props
  const [items, setItems] = useState(initialItems)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  function updateItems(newItems) {
    setItems(newItems)
    setLoading(false)
  }

  useEffect(() => {
    if (initialItems.length) {
      return
    }

    let collection = DatabaseService.firestore.collection(
      DatabaseService.COLLECTIONS.users
    )

    return collection.orderBy('createdAt', 'desc').onSnapshot({
      next: ({ docs } = {}) => {
        updateItems(
          docs.map(doc => ({
            ...doc.data(),
            $id: doc.id,
            $ref: doc.ref,
          }))
        )
      },
      error: error => {
        console.error(error)
        setLoading(false)
        setError(error)
      },
    })
  }, [])

  if (loading) {
    return (
      <div className={styles.loadingBox}>
        <LoadingDots loading={true} />
      </div>
    )
  }

  if (error) {
    return <div>Something went wrong....</div>
  }

  return (
    <div className={styles.container}>
      <div className={styles.total}>total: {items.length}</div>
      {items.map(item => {
        return <UserListItem key={`item-${item.$id}`} item={item} />
      })}
    </div>
  )
}
