import { useLayoutEffect, useState } from 'react'
import DatabaseService from 'services/DatabaseService'
import AuthService from 'services/AuthService'

/**
 * Gets the user's role
 * @param {*} role
 * @param {*} callback
 */
export default function useUserRoles(roles = ['*']) {
  const [isLoggedIn, setIsLoggedIn] = useState(null)
  // const [userRole, setUserRole] = useState(null)
  const [matches, setMatches] = useState(null)

  /** @type {firebase.auth.Auth} */
  const auth = AuthService.auth
  // TODO: Maybe extrapolate the following code into an
  // observer that attaches to DatabaseService in order
  // to having multiple listeners(?)
  useLayoutEffect(() => {
    return auth.onAuthStateChanged(user => {
      if (!user) {
        setIsLoggedIn(false)
      } else {
        setIsLoggedIn(true)
      }
    })
  }, [])

  useLayoutEffect(() => {
    /** @type {firebase.firestore.Firestore} */
    const firestore = DatabaseService.firestore

    if (isLoggedIn === null) {
      // do nothing as we don't know the user's state yet
      return
    }

    if (isLoggedIn === false) {
      setMatches(roles.includes('*'))
      return
    }

    return firestore.doc(`users/${auth.currentUser.uid}`).onSnapshot({
      next: snap => {
        if (!snap.exists) {
          setMatches(roles.includes('*'))
          return
        }
        const { role = 'user' } = snap.data()
        setMatches(roles.includes(role) || roles.includes('*'))
      },
      error: error => {
        console.error(error)
      },
    })
  }, [isLoggedIn])
  return matches
}
